<template>
  <div class="flex flex-col">
    <div v-for="col in [0, 1, 2, 3]" :key="col">
      <div class="flex w-full gap-8">
        <Skeleton
          v-for="row in ['40%', '15%', '15%', '10%']"
          :key="row"
          height="22px"
          :width="row"
        />
      </div>

      <hr class="my-4 h-px border-t-0 bg-gray-800" />
    </div>
  </div>
</template>
